<template lang="pug">
.error
  .error__cont.cont
    .error__wrapper
      h1.error__caption {{ t('caption') }}
      p.error__text {{ t('text') }}
      router-link.error__link.button(:to="{ name: 'Home', params: { locale: $i18n.locale } }") {{ t('button') }}
</template>

<script>
import '@/assets/styles/components/error.sass'
import { useI18n } from 'vue-i18n'

export default {
  name: 'TheError',
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  }
}
</script>

<i18n>
{
  "ru": {
    "caption": "Страница не найдена",
    "text": "Она была удалена или вовсе не существовала",
    "button": "Вернуться на главную"
  },
  "en": {
    "caption": "Page not found",
    "text": "It was deleted or did not exist at all",
    "button": "Back to the home page"
  },
  "de": {
    "caption": "Seite nicht gefunden",
    "text": "Sie wurde entfernt oder existiert gar nicht mehr",
    "button": "Zurück zur Hauptseite"
  },
  "es": {
    "caption": "Página no encontrada",
    "text": "Se eliminó o no existía en absoluto.",
    "button": "Regresa a la página principal"
  },
  "pt": {
    "caption": "Página não encontrada",
    "text": "Foi deletado ou não existia",
    "button": "Voltar para a página principal"
  },
  "fr": {
    "caption": "Page non trouvée",
    "text": "Il a été supprimé ou n'existait pas du tout",
    "button": "Revenez à la page principale"
  },
  "it": {
    "caption": "Pagina non trovatae",
    "text": "È stato cancellato o non esisteva affatto",
    "button": "Torna alla pagina principale"
  },
  "pl": {
    "caption": "Strona nie znaleziona",
    "text": "Został usunięty lub w ogóle nie istniał",
    "button": "Wróć do strony głównej"
  },
  "zh": {
    "caption": "找不到網頁",
    "text": "它已被刪除或根本不存在",
    "button": "返回主頁面"
  },
  "ja": {
    "caption": "ページが見つかりません",
    "text": "削除されたか、まったく存在しませんでした",
    "button": "メインページに戻る"
  }
}
</i18n>
