<template lang="pug">
the-error
</template>

<script>
import TheError from '../components/error/TheError'

export default {
  components: { TheError },
  name: 'ErrorPage'
}
</script>
