export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не найдена"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Она была удалена или вовсе не существовала"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться на главную"])}
      },
      "en": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was deleted or did not exist at all"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the home page"])}
      },
      "de": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wurde entfernt oder existiert gar nicht mehr"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Hauptseite"])}
      },
      "es": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se eliminó o no existía en absoluto."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresa a la página principal"])}
      },
      "pt": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página não encontrada"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi deletado ou não existia"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para a página principal"])}
      },
      "fr": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il a été supprimé ou n'existait pas du tout"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenez à la page principale"])}
      },
      "it": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovatae"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato cancellato o non esisteva affatto"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla pagina principale"])}
      },
      "pl": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona nie znaleziona"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Został usunięty lub w ogóle nie istniał"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do strony głównej"])}
      },
      "zh": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到網頁"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["它已被刪除或根本不存在"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主頁面"])}
      },
      "ja": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページが見つかりません"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されたか、まったく存在しませんでした"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メインページに戻る"])}
      }
    }
  })
}
